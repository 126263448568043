import { CommonModule, DOCUMENT, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { BreadcrumbComponent } from '@app/components/breadcrumb/breadcrumb.component';
import { FooterComponent } from '@app/components/footer/footer.component';
import { NavigationService } from '@app/core/services/navigation.service';
import { HeaderComponent } from '@app/header/components/header/header.component';
import { ModalOutletComponent } from '@app/modals/components/modal-outlet/modal-outlet.component';
import { LoginModalContentComponent } from '@app/page/components/login-modal-content/login-modal-content.component';
import { NotAuthorizedModalContentComponent } from '@app/page/components/not-authorized-modal-content/not-authorized-modal-content.component';
import { TeleportOutletDirective } from '@app/teleport/directives/teleport-outlet.directive';
import { ToastMessageListComponent } from '@app/toast/components/toast-message-list/toast-message-list.component';
import { UserService } from '@app/user/services/user.service';
import { OperationalNotificationComponent } from '@core/components/operational-notification/operational-notification.component';
import { ScrollbarRulerComponent } from '@core/components/scrollbar-ruler/scrollbar-ruler.component';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { AppLayout } from '@core/interfaces/project/app-layout.interface';
import { AppPageComponent } from '@core/interfaces/project/app-page-component.interface';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
import { CoreService } from '@core/services/core.service';
import { OperationalNotificationService } from '@core/services/operational-notification.service';
import { getLocalStorageKeyForDismissedOperationalNotifications } from '@core/store/core.utils';
import { SessionStorageNames } from '@core/store/enums';
import buildVersion from '@env/build-version';
import { environment } from '@env/environment';
import { concatLatestFrom } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { routeAnimation } from '@shared-animations/route.animation';
import { SkipToContentButtonComponent } from '@shared-components/accessibility/skip-to-content-button/skip-to-content-button.component';
import { BackgroundGraphicsManagerComponent } from '@shared-components/background-graphics-manager/background-graphics-manager.component';
import { ModalComponent } from '@shared-components/modals/modal/modal.component';
import { IconButtonComponent } from '@shared-components/ui/icon-button/icon-button.component';
import {
  LoadingAnimationComponent,
  LoadingAnimationVariant,
} from '@shared-components/ui/loading-animation/loading-animation.component';
import { PageTitleComponent } from '@shared-components/ui/page-title/page-title.component';
import { ServerErrorMessageComponent } from '@shared-components/ui/server-error-message/server-error-message.component';
import { Icons } from '@shared-data/icons';
import { IconSize } from '@shared-enums/icon-size.enum';
import { PageLayout } from '@shared-enums/page-layout.enum';
import { DropdownOutletComponent } from '@shared-modules/dropdown/components/dropdown-outlet/dropdown-outlet.component';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';
import { TooltipOutletComponent } from '@shared-modules/tooltip/components/tooltip-outlet/tooltip-outlet.component';
import { GUID } from '@shared-types/guid.type';
import { RouterLinkCommands } from '@shared-types/router-link-command.type';
import { scrollToTop } from '@shared-utils/scroll.utils';
import { combineServerInteractionStateObservables } from '@utils/observables';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breadcrumb } from './components/breadcrumb/interfaces/breadcrumb.interface';
import { PageService } from './page/services/page.service';
import { getLocalStorageKeyForGlobalTeamId } from './teams/store/teams.utils';
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ScrollbarRulerComponent,
    DropdownOutletComponent,
    TooltipOutletComponent,
    ToastMessageListComponent,
    ModalOutletComponent,
    NotAuthorizedModalContentComponent,
    ModalComponent,
    LoginModalContentComponent,
    FooterComponent,
    PageTitleComponent,
    RouterOutlet,
    ServerErrorMessageComponent,
    IconButtonComponent,
    BreadcrumbComponent,
    HeaderComponent,
    SkipToContentButtonComponent,
    LoadingAnimationComponent,
    BackgroundGraphicsManagerComponent,
    TranslateSharedModule,
    TeleportOutletDirective,
    OperationalNotificationComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routeAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostListener('window:focus')
  onWindowFocus(): void {
    // Reload user data every time this browser tab re-gains focus, to make sure we're not displaying stale
    // user data when the session times out in the background or the user is changed in another tab.
    this.reloadUserData(true);
  }

  protected readonly buildVersion = buildVersion;

  @HostBinding('attr.data-page-layout')
  public pageLayout = PageLayout.DEFAULT;
  public toastBottomOffset = 0;
  public mainElementId = 'main';
  public backButtonRouterLink: RouterLinkCommands = '';
  public exitButtonRouterLink: RouterLinkCommands = '';
  public breadcrumbs$: Observable<Breadcrumb[]> = of([]);
  public previousBreadcrumb$: Observable<Breadcrumb | undefined> = of();
  public pageTitle$: Observable<string> = of('');

  public combinedServerInteractionState$ = combineServerInteractionStateObservables(
    [
      this.azureAppConfigurationService.azureAppConfigurationServerInteractionState$,
      this.coreService.loadSiteBootstrapServerInteractionState$,
      this.userService.userServerInteractionState$,
    ],
    { treatInitialAsLoading: true },
  );

  public Icons = Icons;
  public IconSize = IconSize;
  public LoadingAnimationVariant = LoadingAnimationVariant;
  public PageLayout = PageLayout;
  public ServerInteractionStateType = ServerInteractionStateType;

  private documentTitleSub?: Subscription;
  private userSub?: Subscription;
  private clearLocalStorageSub?: Subscription;

  constructor(
    public azureAppConfigurationService: AzureAppConfigurationService,
    public coreService: CoreService,
    public navigationService: NavigationService,
    public translate: TranslateService,
    public userService: UserService,
    public pageService: PageService,
    protected operationalNotificationService: OperationalNotificationService,
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private childrenOutletContexts: ChildrenOutletContexts,
    private router: Router,
    private titleService: Title,
    private location: Location,
  ) {
    navigationService.initialize();

    const language = localStorage.getItem(SessionStorageNames.TranslationServiceLocale) || environment.defaultLanguage;

    translate.use(language);
    this.document.documentElement.lang = language;
  }

  ngOnInit(): void {
    this.clearLocalStorageSub = this.azureAppConfigurationService
      .getFeatureFlagObservable('clearLocalStorage')
      .pipe(concatLatestFrom(() => [this.userService.userId$]))
      .subscribe(([clearLocalStorage, userId]) => {
        if (clearLocalStorage && userId) {
          // The following restoration of local storage data only works within a login session.
          // As soon as another user logs in on the same machine, the previous user will lose their data,
          // because the data related to their user ID isn't restored.

          // Get the local storage keys to restore after clearing the local storage
          const globalTeamIdKey = getLocalStorageKeyForGlobalTeamId(userId);
          const dismissedNotificationTokensKey = getLocalStorageKeyForDismissedOperationalNotifications(userId);

          // Extract the data to restore
          const teamId = localStorage.getItem(globalTeamIdKey) as GUID | null;
          const dismissedNotificationTokens = localStorage.getItem(dismissedNotificationTokensKey);

          localStorage.clear();

          // Restore data if it exists
          if (teamId) {
            localStorage.setItem(globalTeamIdKey, teamId);
          }
          if (dismissedNotificationTokens) {
            localStorage.setItem(dismissedNotificationTokensKey, dismissedNotificationTokens);
          }
        }
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Update the back button
        this.backButtonRouterLink = this.getRouteBackButtonRouterLink();
        this.exitButtonRouterLink = this.getRouteExitButtonRouterLink();

        // Update the global layout to match the current route
        const layoutData = this.extractLayoutData(this.activatedRoute);

        this.pageLayout = layoutData.pageLayout ?? PageLayout.DEFAULT;
        this.toastBottomOffset = layoutData.toastBottomOffset ?? 0;
        this.mainElementId = layoutData.mainElementId ?? 'main';

        /*
        Scroll to top when navigating to new views
        But NOT when in search and making a new search query
         */
        let findcontentUrl = this.pageService.getUrlForPageType('mf20-find-content');
        if (findcontentUrl && event.url.includes(findcontentUrl) && event.url !== findcontentUrl) {
          return;
        } else {
          setTimeout(() => {
            scrollToTop();
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.documentTitleSub?.unsubscribe();
    this.userSub?.unsubscribe();
    this.clearLocalStorageSub?.unsubscribe();
  }

  /**
   * Returns a unique value (the component name) for every page that should animate.
   * Returns `fullscreen` for the full screen pages, thus preventing them from animating.
   */
  public getRouteAnimationIdentifier(): string {
    const snapshot = this.childrenOutletContexts.getContext('primary')?.route?.snapshot;

    if (this.pageLayout === PageLayout.FULLSCREEN) {
      return 'fullscreen';
    }

    return snapshot?.routeConfig?.component?.name || '';
  }

  /**
   * Extracts the back button router link from the route data.
   */
  public getRouteBackButtonRouterLink(): RouterLinkCommands {
    const snapshot = this.childrenOutletContexts.getContext('primary')?.route?.snapshot;
    if (snapshot?.data.backButtonRouterLink) {
      const _backButtonRouterLink = snapshot?.data.backButtonRouterLink as string;
      if (_backButtonRouterLink.startsWith('..')) {
        const _routerUrl = this.router.url.split('/').splice(1);
        const _backList = _backButtonRouterLink.split('/');
        _backList.forEach((x) => _routerUrl.pop());
        return '/' + _routerUrl.join('/');
      }
      return snapshot.data.backButtonRouterLink;
    }
    return '';
  }

  /**
   * Extracts the exit button router link from the route data.
   */
  public getRouteExitButtonRouterLink(): RouterLinkCommands {
    const snapshot = this.childrenOutletContexts.getContext('primary')?.route?.snapshot;

    const path = snapshot?.data.exitButtonRouterLink;

    if (path) {
      return this.navigationService.createAbsolutePath(path);
    }

    return '';
  }

  /**
   * Extracts the layout data for the given route and all of its children and returns it as a merged layout data object.
   */
  private extractLayoutData(route: ActivatedRoute): AppLayout {
    let layoutData = route.snapshot.data.layout;

    route.children.forEach((childRoute) => {
      layoutData = { ...layoutData, ...this.extractLayoutData(childRoute) };
    });

    return layoutData;
  }

  reloadAzureAppConfiguration(): void {
    this.azureAppConfigurationService.reload();
  }

  reloadUserData(noLoadingAnimation?: boolean): void {
    this.userService.reloadUserData(noLoadingAnimation);
  }

  reloadPortalConfiguration(): void {
    this.coreService.reloadPortalConfiguration();
  }

  onRouterOutletActivate(component: AppPageComponent): void {
    this.updateDocumentTitle(component);
    this.updatePageTitle(component);
    this.updateBreadcrumb(component);
  }

  /**
   * Updates the page title based on the current route component
   */
  private updateDocumentTitle(component: AppPageComponent): void {
    const fallbackTitle = this.translate.instant('app.name');

    // Cancel any existing document title subscription to avoid race wrong page titles and/or conditions
    this.documentTitleSub?.unsubscribe();

    // Check if the component has a document title
    // (it's required by AppPageComponent, but it's possible to route to
    // components that doesn't implement the AppPageComponent interface)
    if (component.documentTitle$) {
      this.documentTitleSub = component.documentTitle$.subscribe((documentTitle) => {
        this.titleService.setTitle(documentTitle || fallbackTitle);
      });
    } else {
      this.titleService.setTitle(fallbackTitle);
    }
  } /**
   * Updates the page title based on the current route component
   */
  private updatePageTitle(component: AppPageComponent): void {
    this.pageTitle$ = component.pageTitle$ || of('');
  }

  /**
   * Updates the breadcrumb based on the current route component
   */
  private updateBreadcrumb(component: AppPageComponent): void {
    if (component.breadcrumbs$) {
      this.breadcrumbs$ = component.breadcrumbs$;
    } else {
      this.breadcrumbs$ = of([]);
    }

    this.previousBreadcrumb$ = this.breadcrumbs$.pipe(map((breadcrumbs) => breadcrumbs.at(-2)));
  }

  protected onLoginModalVisibleChange(visible: boolean): void {
    this.pageService.displayLoginModal = visible;

    // Update the URL to match the frontpage (which is already displayed behind the modal in case the login modal is visible)
    // This makes sense because the `204 not logged in` page is re-using the frontpage and adding the login modal on top
    if (!visible) {
      this.location.replaceState('/');
    }
  }

  protected onNotAuthorizedModalVisibleChange(visible: boolean): void {
    this.pageService.displayNotAuthorizedModal = visible;

    // Update the URL to match the frontpage (which is already displayed behind the modal in case the login modal is visible)
    // This makes sense because the `403 not authorized` page is re-using the frontpage and adding the login modal on top
    if (!visible) {
      this.location.replaceState(this.pageService.notAuthorizedModalBackgroundPageUrl);
    }
  }
}
